<template>
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M24.5 7.04761V13.1428H22.1316V7.04761C22.1316 6.61453 21.7526 6.40601 21.5 6.40601C21.4211 6.40601 21.3421 6.42205 21.2632 6.45413L8.74211 11.2501C7.90526 11.5709 7.36842 12.3729 7.36842 13.2872V14.3619C5.93158 15.4526 5 17.201 5 19.1739V13.2872C5 11.3784 6.15263 9.67818 7.90526 9.0045L20.4421 4.19248C20.7895 4.06416 21.1526 4 21.5 4C23.079 4 24.5 5.29924 24.5 7.04761ZM34.9999 23.97V25.574C34.9999 26.0071 34.6683 26.3599 34.2262 26.376H31.921C31.0841 26.376 30.3262 25.7504 30.2631 24.9163C30.2157 24.4191 30.4052 23.9539 30.721 23.6331C31.0052 23.3284 31.3999 23.168 31.8262 23.168H34.2104C34.6683 23.184 34.9999 23.5369 34.9999 23.97ZM33.4211 21.4839H31.8105C30.9263 21.4839 30.1211 21.8368 29.5368 22.4463C28.7474 23.2323 28.3526 24.4032 28.6842 25.6222C29.0789 27.114 30.5316 28.0603 32.0474 28.0603H33.4211C34.2895 28.0603 35 28.7821 35 29.6643V29.9691C35 33.2894 32.3316 36.0001 29.0632 36.0001H10.9368C7.66842 36.0001 5 33.2894 5 29.9691V19.1741C5 17.2012 5.93158 15.4528 7.36842 14.3621C8.36316 13.5922 9.59474 13.1431 10.9368 13.1431H29.0632C32.3316 13.1431 35 15.8538 35 19.1741V19.8799C35 20.7621 34.2895 21.4839 33.4211 21.4839ZM12.1053 21.1631H23.1579C23.8053 21.1631 24.3421 20.6177 24.3421 19.9601C24.3421 19.3024 23.8053 18.7571 23.1579 18.7571H12.1053C11.4579 18.7571 10.9211 19.3024 10.9211 19.9601C10.9211 20.6177 11.4579 21.1631 12.1053 21.1631Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'WalletIcon',
    };
</script>
