<template>
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.2853 12.072C14.4569 12.072 13.7853 11.4004 13.7853 10.572C13.7853 9.74359 14.4569 9.07202 15.2853 9.07202H30.9275L30.9275 24.7142C30.9275 25.5426 30.2559 26.2142 29.4275 26.2142C28.599 26.2142 27.9275 25.5426 27.9275 24.7142L27.9275 14.1933L11.6319 30.4889C11.0462 31.0746 10.0964 31.0746 9.51063 30.4889C8.92484 29.9031 8.92484 28.9533 9.51063 28.3675L25.8062 12.072H15.2853Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'ArrowUpDiagonallyIcon',
    };
</script>
