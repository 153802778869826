<template>
    <div class="card">
        <div v-if="hasTitle" class="card__header">
            <div class="card__info">
                <div class="card__icon" :style="{ alignSelf: hasDescription ? 'start' : 'unset' }">
                    <slot name="icon" />
                </div>

                <div class="card__title">
                    <h1>
                        <slot name="title" />
                    </h1>

                    <p v-if="hasDescription">
                        <slot name="description" />
                    </p>
                </div>
            </div>

            <div v-if="hasControls" class="card__controls">
                <slot name="controls" />
            </div>
        </div>

        <div class="card__body">
            <scroll-container>
                <slot />
            </scroll-container>
        </div>
    </div>
</template>

<script>
    import ScrollContainer from '@/components/ScrollContainer';

    export default {
        name: 'Card',
        components: {
            ScrollContainer,
        },
        computed: {
            hasControls() {
                return !!this.$slots.controls;
            },
            hasDescription() {
                return !!this.$slots.description;
            },
            hasTitle() {
                return !!this.$slots.title;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .card {
        display: flex;
        flex-direction: column;
        grid-gap: 1.5rem;
        padding: 1.5rem;
        border-radius: 0.8rem;
        background-color: #10141f;

        ::v-deep > .scroll-container > .scroll-container__content {
            display: flex;
            flex-direction: column;
        }
    }

    .card__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-shrink: 0;
        flex-wrap: wrap;
        grid-column-gap: 2rem;
        grid-row-gap: 1rem;
    }

    .card__info {
        display: flex;
        grid-gap: 0.5rem;
    }

    .card__icon {
        flex-shrink: 0;

        svg {
            fill: #353e54;
            width: 2.2rem;
            height: 2.2rem;
        }
    }

    .card__title {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h1 {
            color: #e5ecf9;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1.6rem;
            font-weight: 325;
            line-height: 1.3;
        }

        p {
            color: #55667f;
            font-family: 'Gotham Book', sans-serif;
            font-size: 1rem;
            font-weight: 325;
            line-height: 1.2;
        }
    }

    .card__body {
        position: relative;
        flex-grow: 1;
        min-height: 0;
    }

    .card > .scroll-container_vertical,
    ::v-deep .card__body > .scroll-container_vertical {
        width: calc(100% + 0.9rem);
        padding-right: 0.9rem;
    }

    @media screen and (min-width: 120em) {
        .card {
            grid-gap: 3rem;
            padding: 3rem;

            &:has(.card__header > .card__controls > *) {
                padding: 2rem;
            }
        }

        .card__info {
            grid-gap: 1rem;
        }

        .card__icon svg {
            width: 2.8rem;
            height: 2.8rem;
        }

        .card__title {
            h1 {
                font-size: 2rem;
            }

            p {
                font-size: 1.4rem;
            }
        }

        .card > .scroll-container_vertical,
        ::v-deep .card__body > .scroll-container_vertical {
            width: calc(100% + 1.65rem);
            padding-right: 1.65rem;
        }
    }
</style>
