<template>
    <div ref="modal" :class="modalClass">
        <div class="filters-modal__header">
            <div class="filters-modal__title">
                <filter-icon />

                <p>{{ $t('statistics.table.filters.search') }}</p>
            </div>

            <icon-button class="filters-modal__close-button" theme="secondary" size="small" @click="$emit('close')">
                <cross-icon />
            </icon-button>
        </div>

        <div class="filters-modal__body">
            <slot />
        </div>
    </div>
</template>

<script>
    import CrossIcon from '@/components/icons/CrossIcon';
    import FilterIcon from '@/components/icons/FilterIcon';
    import IconButton from '@/components/buttons/IconButton';
    import modal from '@/components/Modal.vue';

    export default {
        name: 'FiltersModal',
        components: {
            CrossIcon,
            FilterIcon,
            IconButton,
        },
        computed: {
            modal() {
                return modal;
            },
            modalClass() {
                return ['filters-modal', this.position ? `filters-modal_${this.position}` : null].filter(Boolean);
            },
        },
        methods: {
            initIntersectionObserver() {
                const viewportIO = new IntersectionObserver(
                    entries =>
                        entries.forEach(
                            entry => (this.position = entry.boundingClientRect.height ? (entry.intersectionRatio < 1 ? 'up' : 'down') : null)
                        ),
                    {
                        root: this.$refs.modal.closest('.app-layout__content'),
                    }
                );

                viewportIO.observe(this.$refs.modal);
            },
        },
        data() {
            return {
                position: null,
            };
        },
        mounted() {
            this.initIntersectionObserver();
        },
    };
</script>

<style lang="scss" scoped>
    .filters-modal {
        position: absolute;
        left: 0;
        width: 100%;
        padding: 1.5rem;
        border-radius: 0.8rem;
        background-color: #131927;
        box-shadow: 0 0.1rem 6.4rem rgba(0, 0, 0, 0.24);
        z-index: 1;

        &_down {
            top: 0;
        }

        &_up {
            bottom: 0;
        }
    }

    .filters-modal__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1.5rem;
        border-bottom: 0.1rem solid #191f2f;
    }

    .filters-modal__title {
        display: flex;
        align-items: center;

        svg {
            width: 2.6rem;
            height: 2.6rem;
            margin-right: 0.7rem;
            fill: #353e54;
        }

        p {
            margin-top: 0.1rem;
            color: #9aa2b7;
            font-size: 1.5rem;
        }
    }

    .filters-modal__close-button {
        width: 2.4rem;
        height: 2.4rem;
        border-radius: 0.6rem;

        svg {
            width: 50%;
        }
    }

    .filters-modal__body {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;
        padding-top: 1.5rem;

        ::v-deep > * {
            grid-column: span 2;
        }
    }

    @media screen and (min-width: 30em) {
        .filters-modal__body {
            grid-template-columns: repeat(4, 1fr);

            ::v-deep .date-range-picker {
                grid-column: span 4;
            }
        }
    }

    @media screen and (min-width: 48em) {
        .filters-modal__body {
            grid-template-columns: repeat(6, 1fr);

            ::v-deep {
                .button,
                .select,
                .date-range-picker {
                    grid-column: span 3;
                }
            }
        }
    }

    @media screen and (min-width: 60em) {
        .filters-modal__body {
            grid-template-columns: repeat(8, 1fr);

            ::v-deep {
                .button,
                .select {
                    grid-column: span 2;
                }

                .date-range-picker {
                    grid-column: span 4;
                }
            }
        }
    }
</style>
