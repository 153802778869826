<template>
    <svg viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22.8897 4.30365C22.5693 3.83955 22.1604 3.4307 21.6963 3.11025C20.6466 2.3478 19.2764 1.95 17.6299 1.95H8.37C8.149 1.95 7.928 1.96105 7.71805 1.98315C4.09365 2.2152 1.94995 4.56885 1.94995 8.37005V17.6299C1.94995 19.2764 2.34775 20.6466 3.1102 21.6963C3.43065 22.1604 3.8395 22.5693 4.3036 22.8897C5.2097 23.5527 6.3589 23.9395 7.71805 24.0279C7.928 24.0389 8.149 24.05 8.37 24.05H17.6299C21.6521 24.05 24.0499 21.6521 24.0499 17.6299V8.37005C24.0499 6.7236 23.6522 5.3534 22.8897 4.30365ZM9.08825 11.5414L8.5468 10.9778C8.2595 10.6905 8.02745 10.1712 8.02745 9.8176V8.4916C8.02745 7.79545 8.5468 7.2761 9.1877 7.2761H11.5524C12.0054 7.2761 12.2817 7.77335 12.0386 8.1601L9.99435 11.453C9.79545 11.7734 9.3424 11.8176 9.08825 11.5414ZM17.9724 9.7071C17.9724 10.1712 17.6851 10.7458 17.3978 11.0331L14.9116 13.232C14.569 13.5193 14.337 14.0939 14.337 14.558V17.0443C14.337 17.3868 14.1049 17.8509 13.8176 18.0277L13 18.5471C12.2486 19.0112 11.2099 18.4918 11.2099 17.5636V14.5028C11.2099 14.0939 10.9778 13.5746 10.7458 13.2873L10.4806 13C10.3038 12.8121 10.2706 12.5248 10.4032 12.2928L13.3646 7.5413C13.4751 7.37555 13.6519 7.26505 13.8508 7.26505H16.8122C17.4531 7.26505 17.9724 7.7844 17.9724 8.4253V9.7071V9.7071Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'FilterIcon',
    };
</script>
