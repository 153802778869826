export const isHexColor = color => color.includes('#');

export const hexToRgb = color => {
    const bigint = parseInt(color.replace('#', ''), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
};

export const rgbComponents = color =>
    color
        .replaceAll(/(rgba?\(|\))/gi, '')
        .split(',')
        .map(item => parseInt(item))
        .slice(0, 3);
