<template>
    <span :class="['postback-status', `postback-status_${colorClass}`, loading ? 'postback-status_loading' : '']">
        <span>{{ status }} {{ shortText }}</span>

        <icon-button size="small" :title="$t('statistics.table.retry')" @click="$emit('retry')">
            <retry-arrow-icon />
        </icon-button>
    </span>
</template>

<script>
    import IconButton from '@/components/buttons/IconButton';
    import RetryArrowIcon from '@/components/icons/RetryArrowIcon';

    const HTTP_STATUS_META = {
        2: {
            shortText: 'Ok',
            colorClass: 'success',
        },
        4: {
            shortText: 'Bad',
            colorClass: 'warning',
        },
        5: {
            shortText: 'Error',
            colorClass: 'error',
        },
    };

    export default {
        name: 'PostbackStatus',
        components: {
            IconButton,
            RetryArrowIcon,
        },
        props: {
            loading: {
                type: Boolean,
                default: false,
            },
            status: {
                type: Number,
                required: true,
            },
        },
        computed: {
            shortCode() {
                return Math.floor(this.status / 100);
            },
            shortText() {
                return HTTP_STATUS_META[this.shortCode] ? HTTP_STATUS_META[this.shortCode].shortText.toUpperCase() : '';
            },
            colorClass() {
                return HTTP_STATUS_META[this.shortCode] ? HTTP_STATUS_META[this.shortCode].colorClass : 'primary';
            },
        },
    };
</script>

<style lang="scss" scoped>
    .postback-status {
        display: flex;
        align-items: center;

        button {
            width: 2.2rem;
            height: 2.2rem;
            margin-left: 0.8rem;
            border-radius: 0.6rem;
            background-color: #293162;
            opacity: 0.5;

            svg {
                width: 75%;
                fill: #6590ff;
                animation: none;
                animation-play-state: paused;
            }

            &:hover {
                background-color: #293162;
                opacity: 1;

                svg {
                    fill: #6590ff;
                }
            }
        }

        &_loading {
            button svg {
                animation: rotation 1s linear infinite;
            }
        }

        &_error {
            > span {
                color: #e26868;
            }
        }

        &_primary {
            > span {
                color: #5274cc;
            }
        }

        &_success {
            > span {
                color: #5dd880;
            }
        }

        &_warning {
            > span {
                color: #dca654;
            }
        }
    }

    @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }
</style>
