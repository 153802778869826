<template>
    <td :class="{ 'table-cell': true, [`table-cell_${theme}`]: true, 'table-cell_no-stretch': !stretch }">
        <span v-if="!!$slots.heading" class="table-cell__heading">
            <slot name="heading" />
        </span>

        <span class="table-cell__data">
            <slot name="data" />
        </span>
    </td>
</template>

<script>
    export default {
        name: 'TableCell',
        props: {
            stretch: {
                type: Boolean,
                default: true,
            },
            theme: {
                type: String,
                default: null,
                validator: value => [null, 'error', 'primary', 'secondary', 'success', 'warning'].includes(value),
            },
        },
    };
</script>

<style lang="scss" scoped>
    .table-cell {
        display: flex;
        align-items: center;
        margin-bottom: 0.1rem;
        border-radius: 0.6rem;
        background-color: #131925;

        &_error {
            color: #e26868;
        }

        &_primary {
            color: #5274cc;
        }

        &_secondary {
            color: #82899b;
        }

        &_success {
            color: #5dd880;
        }

        &_warning {
            color: #dca654;
        }

        &_with-hint {
            position: relative;
        }

        &_with-hint:hover &__tooltip {
            display: block;
        }
    }

    .table-cell__heading,
    .table-cell__data {
        padding: 1.5rem;
        font-family: 'Gotham Book', sans-serif;
        font-size: 1.2rem;
        font-weight: 400;
        line-height: normal;
    }

    .table-cell__heading {
        display: flex;
        align-self: stretch;
        flex-shrink: 0;
        width: 12rem;
        border-radius: 0.6rem 0 0 0.6rem;
        background-color: #161c2b;
        color: #555d75;
    }

    .table-cell__data {
        flex-grow: 1;
    }

    .table-cell__tooltip {
        margin-left: 1rem;
        font-size: 1.1rem;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    @media screen and (min-width: 75em) {
        .table-cell {
            display: table-cell;
            height: 5rem;
            margin: 0;
            padding: 0 1.4rem;
            border-radius: 0;
            background-color: #10141f;

            &:first-of-type {
                border-radius: 0.8rem 0 0 0.8rem;
            }

            &:last-of-type {
                border-radius: 0 0.8rem 0.8rem 0;
            }

            &_no-stretch {
                width: 1%;
                white-space: nowrap;
            }
        }

        .table-cell__heading,
        .table-cell__data {
            padding: 0;
        }

        .table-cell__heading {
            display: none;
        }

        .table-cell__tooltip {
            display: none;
            position: absolute;
            bottom: 100%;
            right: 0;
            width: 20rem;
            margin: 0;
            padding: 1rem 2.2rem;
            border-radius: 0.5rem;
            background-color: rgba(104, 118, 151, 0.6);
            backdrop-filter: blur(3px);
            color: #ccd1de;
            font-family: 'Gotham Medium', sans-serif;
            font-weight: 500;
            line-height: 1.2;
            text-align: center;
            text-overflow: unset;
            white-space: unset;
            overflow: unset;

            &:after {
                content: '';
                position: absolute;
                top: 100%;
                left: 4rem;
                margin-left: -0.5rem;
                border: 0.5rem solid;
                border-color: rgba(104, 118, 151, 0.6) transparent transparent transparent;
            }
        }
    }

    @media screen and (min-width: 120em) {
        .table-cell > span {
            font-size: 1.4rem;
        }
    }
</style>
