<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M20.8291 18.7977L16.3185 14.2707C18.2327 11.3915 17.8536 7.56507 15.4089 5.12161C14.0254 3.7576 12.206 3 10.254 3C8.30212 3 6.48257 3.7576 5.11801 5.12161C2.29402 7.96285 2.29402 12.5656 5.11785 15.3883C7.56277 17.8317 11.3912 18.2106 14.2718 16.3163L18.8015 20.8437C19.0101 21.0521 19.3701 21.0521 19.5786 20.8437L20.8295 19.5934C21.0568 19.3661 21.0568 19.0063 20.8294 18.7978L20.8291 18.7977ZM13.7409 13.7399C11.8267 15.6531 8.69971 15.6531 6.76639 13.7399C4.8522 11.8268 4.8522 8.70141 6.76639 6.76912C8.68058 4.85594 11.8076 4.85594 13.7409 6.76912C15.6551 8.70121 15.6551 11.8268 13.7409 13.7399Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'SearchIcon',
    };
</script>
