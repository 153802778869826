import i18n from '@/i18n';

export const STATUS_CANCELLED = 'CANCELLED';

export const STATUS_DECLINED = 'DECLINED';

export const STATUS_DONE = 'DONE';

export const STATUS_DRAFT = 'DRAFT';

export const STATUS_TIMEOUT = 'TIMEOUT';

export const STATUS_WAIT_TRADE_OFFER = 'WAIT_TRADE_OFFER';

export const STATUSES = [
    {
        image: require('@/assets/images/status-error.png'),
        label: () => i18n.t('order.statuses.cancelled'),
        type: 'error',
        value: STATUS_CANCELLED,
    },
    {
        image: require('@/assets/images/status-error.png'),
        label: () => i18n.t('order.statuses.declined'),
        type: 'error',
        value: STATUS_DECLINED,
    },
    {
        image: require('@/assets/images/status-success.png'),
        label: () => i18n.t('order.statuses.done'),
        type: 'success',
        value: STATUS_DONE,
    },
    {
        image: require('@/assets/images/status-pending.png'),
        label: () => i18n.t('order.statuses.draft'),
        type: 'warning',
        value: STATUS_DRAFT,
    },
    {
        image: require('@/assets/images/status-error.png'),
        label: () => i18n.t('order.statuses.timeout'),
        type: 'error',
        value: STATUS_TIMEOUT,
    },
    {
        image: require('@/assets/images/status-pending.png'),
        label: () => i18n.t('order.statuses.wait'),
        type: 'warning',
        value: STATUS_WAIT_TRADE_OFFER,
    },
];
