<template>
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M13.9301 2.57824L11.1185 9.12324H8.3068C7.84013 9.12324 7.38513 9.15824 6.9418 9.25157L8.10846 6.45157L8.15513 6.34657L8.22513 6.1599C8.23557 6.13554 8.24498 6.11221 8.25396 6.08993L8.25397 6.0899C8.2751 6.0375 8.29391 5.99083 8.31846 5.9499C9.6718 2.81157 11.1885 1.83157 13.9301 2.57824ZM21.8517 9.43829L21.8284 9.42662C21.1284 9.22829 20.4167 9.12329 19.6934 9.12329H12.39L15.015 3.02162L15.05 2.93995C15.1224 2.96581 15.1971 2.99625 15.2731 3.02722C15.3685 3.0661 15.4659 3.10581 15.5634 3.13829L18.1417 4.22329C19.5767 4.81829 20.58 5.43662 21.1984 6.18329C21.3034 6.32329 21.3967 6.45162 21.49 6.60329C21.595 6.76662 21.6767 6.92995 21.7234 7.10495C21.77 7.20995 21.805 7.30329 21.8284 7.40829C22.0034 8.00329 22.015 8.67995 21.8517 9.43829ZM19.6933 10.8732C20.2649 10.8732 20.8133 10.9549 21.3383 11.1066C23.6716 11.8182 25.3866 13.9882 25.3866 16.5666V18.8416C25.3866 19.0749 25.3749 19.3082 25.3633 19.5299C25.1416 23.6132 22.8666 25.6666 18.5499 25.6666H9.44995C9.15828 25.6666 8.88995 25.6432 8.62161 25.6082C4.91161 25.3632 2.92828 23.3799 2.67161 19.6699C2.63661 19.3899 2.61328 19.1216 2.61328 18.8416V16.5666C2.61328 14.2216 4.03661 12.2032 6.06661 11.3282C6.76661 11.0249 7.51328 10.8732 8.30661 10.8732H19.6933ZM14.8749 18.8882H16.9166C17.3949 18.8882 17.7916 18.5032 17.7916 18.0132C17.7916 17.5349 17.3949 17.1382 16.9166 17.1382H14.8749V15.1666C14.8749 14.6882 14.4783 14.2916 13.9999 14.2916C13.5216 14.2916 13.1249 14.6882 13.1249 15.1666V17.1382H11.0833C10.6049 17.1382 10.2083 17.5349 10.2083 18.0132C10.2083 18.5032 10.6049 18.8882 11.0833 18.8882H13.1249V20.9999C13.1249 21.4782 13.5216 21.8749 13.9999 21.8749C14.4783 21.8749 14.8749 21.4782 14.8749 20.9999V18.8882Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'DepositIcon',
    };
</script>
