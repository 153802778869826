import { hexToRgb, isHexColor, rgbComponents } from '@/helpers/color';

const drawRectangle = (ctx, x, y, width, height, radius = 0, fill = null) => {
    radius = typeof radius === 'number' ? { tl: radius, tr: radius, br: radius, bl: radius } : { ...{ tl: 0, tr: 0, br: 0, bl: 0 }, ...radius };

    ctx.fillStyle = fill || 'transparent';

    ctx.beginPath();
    ctx.moveTo(x + radius.tl, y);
    ctx.lineTo(x + width - radius.tr, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
    ctx.lineTo(x + width, y + height - radius.br);
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
    ctx.lineTo(x + radius.bl, y + height);
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
    ctx.lineTo(x, y + radius.tl);
    ctx.quadraticCurveTo(x, y, x + radius.tl, y);
    ctx.closePath();
    ctx.fill();
};

// noinspection JSUnusedGlobalSymbols
export const transparentLastYGridLine = {
    id: 'transparentLastYGridLine',
    beforeDraw: chart => {
        chart.ctx.save();

        const gridLines = chart.scales.y['_gridLineItems'];

        if (gridLines) {
            gridLines[gridLines.length - 1].color = 'transparent';
        }

        chart.ctx.restore();
    },
};

// noinspection JSUnusedGlobalSymbols
export const zebraBackgroundPlugin = {
    id: 'zebraBackgroundPlugin',
    beforeDraw: chart => {
        chart.ctx.save();

        const gridLines = chart.scales.x['_gridLineItems'] || [];

        gridLines.slice(0, -1).forEach((gridLine, index) => {
            const isFirst = 0 === index,
                isLast = gridLines.length - 2 === index;

            drawRectangle(
                chart.ctx,
                gridLine.x1,
                gridLine.y1,
                gridLines[index + 1].x1 - gridLine.x1,
                chart.chartArea.height,
                { tl: isFirst ? 8 : 0, bl: isFirst ? 8 : 0, tr: isLast ? 8 : 0, br: isLast ? 8 : 0 },
                0 === index % 2 ? '#151b27' : '#141925'
            );
        });

        chart.ctx.restore();
    },
};

export const chartFillGradient = (chart, color) => {
    if (!chart.chartArea) return;

    color = isHexColor(color) ? hexToRgb(color) : rgbComponents(color);

    let gradient = chart.ctx.createLinearGradient(0, chart.chartArea.top, 0, chart.chartArea.bottom);

    gradient.addColorStop(0, `rgba(${color.join(',')},0.18)`);
    gradient.addColorStop(1, `rgba(${color.join(',')},0)`);

    return gradient;
};
