<template>
    <div :class="['indicator', `indicator_${theme}`]">
        <slot name="icon" />

        <div>
            <p class="indicator__value">{{ prettyValue }}</p>

            <p class="indicator__title">{{ title }}</p>
        </div>

        <slot name="action" />
    </div>
</template>

<script>
    import { formatPrice } from '@/helpers/common';
    import ArrowUpDiagonallyIcon from '@/components/icons/ArrowUpDiagonallyIcon';

    export default {
        name: 'Indicator',
        components: {
            ArrowUpDiagonallyIcon,
        },
        props: {
            title: {
                type: String,
                required: true,
            },
            value: {
                type: [Number, String],
                required: true,
            },
            theme: {
                type: String,
                default: null,
                validator: value => ['info', 'primary', 'success'].includes(value),
            },
        },
        computed: {
            prettyValue() {
                return formatPrice(this.value);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .indicator {
        display: flex;
        align-items: center;
        padding: 1.3rem;
        border-radius: 0.8rem;
        background-color: #111621;
        text-transform: uppercase;

        > svg {
            flex-shrink: 0;
            width: 2.8rem;
            height: 2.8rem;
            margin-right: 0.7rem;
            fill: #a3b8db;
        }

        &_info {
            background: linear-gradient(254.68deg, #121a27 38.57%, #162230 77.48%, #1b2b43 104.8%);

            > svg {
                fill: #6bd4f5;
            }
        }

        &_primary {
            background: linear-gradient(254.68deg, #121827 38.57%, #161e30 77.48%, #1b2743 104.8%);

            > svg {
                fill: #6590ff;
            }
        }

        &_success {
            background: linear-gradient(254.68deg, #141c28 38.57%, #162229 77.48%, #15292d 104.8%);

            > svg {
                fill: #88e998;
            }
        }
    }

    .indicator__title {
        flex-shrink: 0;
        color: #5f728d;
        font-family: 'Gotham Medium', sans-serif;
        font-size: 0.9rem;
        font-weight: 350;
    }

    .indicator__value {
        margin-bottom: 0.5rem;
        color: #e9f8ff;
        font-family: 'Gotham Bold', sans-serif;
        font-size: 1.4rem;
        font-weight: 400;
    }

    @media screen and (min-width: 120em) {
        .indicator {
            padding: 3rem;

            > svg {
                width: 4.2rem;
                height: 4.2rem;
                margin-right: 2rem;
            }
        }

        .indicator__title {
            font-size: 1.2rem;
        }

        .indicator__value {
            font-size: 3rem;
        }
    }
</style>
