<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M10.9295 2.67014C12.7192 2.43453 14.5366 2.79603 16.0998 3.69858C17.6631 4.60113 18.8848 5.99429 19.5756 7.66198C20.2664 9.32968 20.3876 11.1787 19.9204 12.9223C19.4532 14.6659 18.4237 16.2066 16.9917 17.3055C15.7864 18.2303 14.3526 18.7987 12.8526 18.9555L14.8485 20.9514L13.1515 22.6485L8.30294 17.8L13.1515 12.9514L14.8485 14.6485L12.9805 16.5165C13.9037 16.3581 14.7798 15.9776 15.5306 15.4014C16.5436 14.6242 17.2717 13.5344 17.6022 12.3011C17.9326 11.0679 17.8469 9.76001 17.3583 8.58042C16.8697 7.40083 16.0055 6.41543 14.8998 5.77704C13.7941 5.13865 12.5086 4.88295 11.2428 5.04961C9.97691 5.21626 8.80141 5.79595 7.8986 6.69877C6.99578 7.60159 6.41609 8.77708 6.24944 10.0429C6.08278 11.3088 6.33848 12.5943 6.97687 13.7C7.30824 14.2739 7.11159 15.0078 6.53764 15.3392C5.96369 15.6706 5.22978 15.4739 4.89841 14.9C3.99586 13.3367 3.63436 11.5193 3.86997 9.72967C4.10558 7.94002 4.92514 6.27811 6.20154 5.00171C7.47794 3.72531 9.13984 2.90575 10.9295 2.67014Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'RetryArrowIcon',
    };
</script>
