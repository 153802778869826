<template>
    <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.9261 3.10797H19.6333C18.2109 3.10797 17.3597 3.95917 17.3597 5.38157V8.67437C17.3597 10.0968 18.2109 10.948 19.6333 10.948H22.9261C24.3485 10.948 25.1997 10.0968 25.1997 8.67437V5.38157C25.1997 3.95917 24.3485 3.10797 22.9261 3.10797ZM23.1725 6.81517C23.0381 6.94957 22.8589 7.01677 22.6797 7.01677C22.5005 7.01677 22.3213 6.94957 22.1869 6.81517L21.9853 6.61357V9.12237C21.9853 9.51437 21.6717 9.82797 21.2797 9.82797C20.8877 9.82797 20.5741 9.51437 20.5741 9.12237V6.61357L20.3725 6.81517C20.1037 7.08397 19.6557 7.08397 19.3869 6.81517C19.1181 6.54637 19.1181 6.09837 19.3869 5.82957L20.7869 4.42957C20.8429 4.37357 20.9213 4.32877 20.9997 4.29517C21.0109 4.28957 21.0221 4.28677 21.0333 4.28397C21.0445 4.28117 21.0557 4.27837 21.0669 4.27277C21.1229 4.25037 21.1789 4.23917 21.2461 4.23917H21.3133C21.3917 4.23917 21.4589 4.25037 21.5373 4.28397H21.5597C21.6381 4.31757 21.7053 4.36237 21.7613 4.41837C21.7725 4.42957 21.7725 4.42957 21.7837 4.42957L23.1837 5.82957C23.4525 6.09837 23.4525 6.54637 23.1725 6.81517ZM2.7998 13.9832V19.5832C2.7998 22.148 4.8718 24.22 7.4366 24.22H20.5518C23.1166 24.22 25.1998 22.1368 25.1998 19.572V13.9832C25.1998 13.2328 24.595 12.628 23.8446 12.628H4.155C3.4046 12.628 2.7998 13.2328 2.7998 13.9832ZM9.51981 20.468H7.27981C6.82061 20.468 6.43981 20.0872 6.43981 19.628C6.43981 19.1688 6.82061 18.788 7.27981 18.788H9.51981C9.97901 18.788 10.3598 19.1688 10.3598 19.628C10.3598 20.0872 9.97901 20.468 9.51981 20.468ZM16.7998 20.468H12.3198C11.8606 20.468 11.4798 20.0872 11.4798 19.628C11.4798 19.1688 11.8606 18.788 12.3198 18.788H16.7998C17.259 18.788 17.6398 19.1688 17.6398 19.628C17.6398 20.0872 17.259 20.468 16.7998 20.468ZM15.6798 9.59266V6.31106C15.6798 5.56066 15.075 4.95586 14.3246 4.95586H7.4366C6.1598 4.95586 4.995 5.47106 4.155 6.31106C3.315 7.15106 2.811 8.30466 2.7998 9.57026C2.7998 10.3207 3.3934 10.9479 4.155 10.9479H14.3246C15.075 10.9479 15.6798 10.3431 15.6798 9.59266Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'WithdrawIcon',
    };
</script>
