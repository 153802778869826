<template>
    <div class="modal">
        <div class="modal__header">
            <h2>{{ title }}</h2>

            <cross-icon v-if="closeButton" @click.native="$emit('close')" />
        </div>

        <div class="modal__body">
            <slot />
        </div>
    </div>
</template>

<script>
    import CrossIcon from '@/components/icons/CrossIcon';

    export default {
        name: 'Modal',
        components: {
            CrossIcon,
        },
        props: {
            closeButton: {
                type: Boolean,
                default: true,
            },
            title: {
                type: String,
                required: true,
            },
        },
    };
</script>

<style lang="scss" scoped>
    .modal {
        width: 52rem;
        max-width: 100%;
        background-color: #151a27;
        border-radius: 0.8rem;
        box-shadow: 0 0.1rem 6.4rem rgba(0, 0, 0, 0.24);
        backdrop-filter: blur(0.5rem);
        overflow: hidden;
    }

    .modal__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 0.1rem solid #0c1018;

        h2 {
            color: #ffffff;
            font-family: 'Gotham Bold', sans-serif;
            font-weight: 400;
        }

        svg {
            width: 1.6rem;
            height: 1.6rem;
            fill: #2c3549;
            cursor: pointer;
            transition: fill 0.3s;

            &:hover {
                fill: #3d4a66;
            }
        }
    }

    .modal__body {
        padding: 1rem;
    }

    @media screen and (min-width: 22.5em) {
        .modal__header,
        .modal__body {
            padding: 1.5rem;
        }
    }

    @media screen and (min-width: 120em) {
        .modal__header,
        .modal__body {
            padding: 3rem 4rem;
        }

        .modal__header {
            h2 {
                font-size: 1.8rem;
            }

            svg {
                width: 2.2rem;
                height: 2.2rem;
            }
        }
    }
</style>
