export const THEME_INFO = 'info';

export const THEME_PRIMARY = 'primary';

export const THEME_OUTLINED = 'primary-outlined';

export const THEME_SUCCESS = 'success';

export const THEME_SECONDARY = 'secondary';

export const THEME_COLORS = [THEME_INFO, THEME_PRIMARY, THEME_OUTLINED, THEME_SUCCESS, THEME_SECONDARY];
