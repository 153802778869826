import i18n from '@/i18n';

export const STATUS_CREATED = 'CREATED';

export const STATUS_PROCESSING = 'PROCESSING';

export const STATUS_WAITING = 'WAITING_ACCEPT';

export const STATUS_ACCEPTED = 'ACCEPTED';

export const STATUS_CANCELED = 'CANCELED';

export const STATUS_TIMEOUT = 'TIMEOUT';

export const STATUS_ERROR_INVALID_TOKEN = 'ERROR_INVALID_TOKEN';

export const STATUS_ERROR_USER_NOT_TRADABLE = 'ERROR_USER_NOT_TRADABLE';

export const STATUS_ERROR_CREATE_FAILED = 'ERROR_CREATE_FAILED';

export const STATUSES = [
    {
        image: require('@/assets/images/status-pending.png'),
        label: () => i18n.t('withdraw.statuses.created'),
        type: 'warning',
        value: STATUS_CREATED,
    },
    {
        image: require('@/assets/images/status-pending.png'),
        label: () => i18n.t('withdraw.statuses.processing'),
        type: 'warning',
        value: STATUS_PROCESSING,
    },
    {
        image: require('@/assets/images/status-pending.png'),
        label: () => i18n.t('withdraw.statuses.waiting'),
        type: 'warning',
        value: STATUS_WAITING,
    },
    {
        image: require('@/assets/images/status-success.png'),
        label: () => i18n.t('withdraw.statuses.accepted'),
        type: 'success',
        value: STATUS_ACCEPTED,
    },
    {
        image: require('@/assets/images/status-error.png'),
        label: () => i18n.t('withdraw.statuses.cancelled'),
        type: 'error',
        value: STATUS_CANCELED,
    },
    {
        image: require('@/assets/images/status-error.png'),
        label: () => i18n.t('withdraw.statuses.timeout'),
        type: 'error',
        value: STATUS_TIMEOUT,
    },
    {
        image: require('@/assets/images/status-error.png'),
        label: () => i18n.t('withdraw.statuses.invalid_token'),
        type: 'error',
        value: STATUS_ERROR_INVALID_TOKEN,
    },
    {
        image: require('@/assets/images/status-error.png'),
        label: () => i18n.t('withdraw.statuses.user_not_tradable'),
        type: 'error',
        value: STATUS_ERROR_USER_NOT_TRADABLE,
    },
    {
        image: require('@/assets/images/status-error.png'),
        label: () => i18n.t('withdraw.statuses.create_failed'),
        type: 'error',
        value: STATUS_ERROR_CREATE_FAILED,
    },
];
