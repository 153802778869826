<template>
    <div class="status">
        <div :class="['status__icon', `status__icon_${status.type}`]" :title="status.label()" />

        <p v-if="withLabel" :class="['status__label', `status__label_${status.type}`]">{{ status.label() }}</p>
    </div>
</template>

<script>
    import _ from 'underscore';
    import { STATUSES as DEPOSIT_STATUSES } from '@/services/skinsPaymentApi/constants/orderStatus';
    import { STATUSES as WITHDRAW_STATUSES } from '@/services/skinsPaymentApi/constants/withdrawStatus';

    const STATUSES = _.union(DEPOSIT_STATUSES, WITHDRAW_STATUSES);

    export default {
        name: 'Status',
        props: {
            statusName: {
                type: String,
                required: true,
                validator: value => _.uniq(_.pluck(STATUSES, 'value')).includes(value),
            },
            withLabel: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                status: _.where(STATUSES, { value: `${this.statusName}` })[0],
            };
        },
    };
</script>

<style lang="scss" scoped>
    .status {
        display: flex;
        align-items: center;
    }

    .status__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.2rem;
        height: 2.2rem;
        border-radius: 0.6rem;

        &:before {
            width: 1.6rem;
            height: 1.6rem;
        }

        &_error {
            background-color: #bf4c4c;

            &:before {
                content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAC5SURBVHgB3ZJNDgIhDIVbRxOXszFx53gUTu5RxJ2JGy4wg7S0CYXqXpuQ8Pe98loAfj6wXcyny50nE4b0jNGcnZcF1nyjeXo9rrq/M3JbGRn4IgM9TGebIWBvVisGmDiLioS6L3CGyHc+WeBsc8lGIlgALADIqwROyVobBAaRKuDCowWNIz2bwRpUqYN707HQFiyLBbXjdAe/wlqwtiadiG1jB5Nn9k1CtCctbhHvHwwFMyLdP/iDeAO/PXaj6kO2DwAAAABJRU5ErkJggg==');
            }
        }

        &_success {
            background-color: #4db26a;

            &:before {
                content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADVSURBVHgB3VI7DoJAEB2RAmkYBRMrmSPgDWjtPIqeQL2BrZVH8AjGE8AN/NQUa2NoyLo7EgnJYqDUl0w2O/ve7MzbBfh59LuQcULkDLzEcT3Mn4+zzlmtxUgIhTyBBOIo0boA2HAoxVcorBV0AQa0wSCU6IcX1Qk1E8cUGcRLFuswnFdEnxYlMdFmcU6tH3EwXZt0lQcFpDwfQKTNUjfHbBpD7kR235oK9Gpd6PlseeQiFVKR3WbQgNo/yHMh1PvuHXeot/Hb8d5c56ErcKTM++b4/+AFy/1CjEGS57QAAAAASUVORK5CYII=');
            }
        }

        &_warning {
            background-color: #dca654;

            &:before {
                content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFqSURBVHgBtVM9TsMwFLbDYlokHJpKTI2PwA0IW8eubL0BWZkaTtAzcAPWToSNsZygoRtqEM5SMlAez47j1GlBSIg35Pn9f/meTcgfhX4X4IGIMCq0ASSTeZb+qgHvizEBmKpjKzPDTyJX2e22+6A1NcFxqpjpAqB3hFLUaBONZsQ6x7RcF+lOAz25KsZBEMvV8pJ1eYGNZjJ/vmbMV+chBiPW9bNyLZ9a0MMFD0Lg/cFVgwjtIHyz9omI274aeqQDvXDh+nUy7B2kSEbxDEHC6DnZI/xUiMaiqVZAwqZBLUClayvmUTZwb5uAux3PSSQQOQ029ELHABF+fFbcUDgzaDMXJhJj/i12/FwIHgymlTYkbnFl18gO/RfsOsLjkHX8Atf0qPxlKSXufcaO+NiumdC4fK/WSF0UYoJJiYUIdI7wJfHw18AQjXGZL2/qmt2r3NMXamI304jEhrF8da/yT4/pvPWYHsh/yBeU4osDdtghiAAAAABJRU5ErkJggg==');
            }
        }
    }

    .status__label {
        margin-left: 0.8rem;

        &_error {
            color: #bf4c4c;
        }

        &_success {
            color: #4db26a;
        }

        &_warning {
            color: #dca654;
        }
    }
</style>
