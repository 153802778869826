<template>
    <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M36.6663 13.0168V20.8335H29.8663C29.6663 20.8168 29.233 20.5668 29.133 20.3835L27.3997 17.1002C26.7163 15.8002 25.533 15.0668 24.2663 15.1335C22.9997 15.2002 21.9163 16.0502 21.3663 17.4335L19.0663 23.2002L18.733 22.3335C17.9163 20.2168 15.583 18.6168 13.283 18.6168L3.33301 18.6668V13.0168C3.33301 6.95016 6.94967 3.3335 13.0163 3.3335H26.983C33.0497 3.3335 36.6663 6.95016 36.6663 13.0168ZM36.6673 26.9835V23.3335H29.8673C28.7507 23.3335 27.434 22.5335 26.9173 21.5502L25.184 18.2668C24.7173 17.3835 24.0507 17.4335 23.684 18.3502L19.8507 28.0335C19.434 29.1168 18.734 29.1168 18.3007 28.0335L16.4007 23.2335C15.9507 22.0668 14.5507 21.1168 13.3007 21.1168L3.33398 21.1668V26.9835C3.33398 32.9502 6.83398 36.5502 12.7173 36.6502C12.9007 36.6668 13.1007 36.6668 13.284 36.6668H26.6173C26.8673 36.6668 27.1173 36.6668 27.3507 36.6502C33.2007 36.5168 36.6673 32.9335 36.6673 26.9835ZM3.3332 26.6834V21.1667H3.2832V25.0001C3.2832 25.5834 3.29987 26.1501 3.3332 26.6834Z"
        />
    </svg>
</template>

<script>
    export default {
        name: 'PulseIcon',
    };
</script>
