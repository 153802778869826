<template>
    <input-component class="date-range-picker" ref="datePickerInput" v-bind="attributes">
        <template v-for="slot in Object.keys($slots)" #[slot]>
            <slot :name="slot" />
        </template>
    </input-component>
</template>

<script>
    import AirDatepicker from 'air-datepicker';
    import { DateTime } from 'luxon';
    import en from 'air-datepicker/locale/en';
    import ru from 'air-datepicker/locale/ru';
    import InputComponent from '@/components/inputs/InputComponent';

    export default {
        name: 'DateRangePickerInput',
        components: {
            InputComponent,
        },
        props: {
            value: Array,
        },
        computed: {
            attributes() {
                return {
                    ...this.$attrs,
                    placeholder: this.$t('statistics.chart.datepicker.placeholder'),
                    value:
                        this.datepicker && this.value
                            ? this.value.map(date => date.toFormat(this.datepicker.opts.dateFormat)).join(this.datepicker.opts.multipleDatesSeparator)
                            : null,
                };
            },
            locale() {
                return this.$i18n.locale;
            },
        },
        methods: {
            initDatepicker() {
                const isMobile = this.$store.state.isMobile;

                this.datepicker = new AirDatepicker(this.$refs.datePickerInput.$refs.input, {
                    isMobile,
                    locale: this.locales[this.$i18n.locale],
                    autoClose: true,
                    range: true,
                    container: !isMobile ? this.$refs.datePickerInput.$refs.wrapper : '',
                    buttons: [
                        {
                            content: () => this.$t('statistics.chart.datepicker.reset'),
                            onClick: dp => dp.clear(),
                        },
                    ],
                    dateFormat: 'dd.MM.yyyy',
                    multipleDatesSeparator: ' — ',
                    navTitles: {
                        days: '<span>MMMM</span>&nbsp;<span>yyyy</span>',
                    },
                    position: isMobile
                        ? 'bottom left'
                        : ({ $datepicker }) => {
                              $datepicker.style.right = '0';
                              $datepicker.style.top = '100%';
                          },
                    onSelect: ({ date }) => {
                        if (2 === date.length) {
                            this.$emit('input', [DateTime.fromJSDate(date[0]), DateTime.fromJSDate(date[1]).endOf('day')]);
                        }
                    },
                });

                if (this.value) {
                    this.datepicker.selectDate(this.value);
                }
            },
        },
        watch: {
            locale(locale) {
                this.datepicker.update({ locale: this.locales[locale] });

                if (this.value) {
                    this.datepicker.selectDate(this.value);
                }
            },
        },
        data() {
            return {
                datepicker: null,
                locales: { en, ru },
            };
        },
        mounted() {
            this.initDatepicker();
        },
    };
</script>
