<template>
    <table class="table">
        <thead>
            <slot name="head" />
        </thead>

        <tbody>
            <slot name="body" />
        </tbody>
    </table>
</template>

<script>
    export default {
        name: 'TableComponent',
    };
</script>

<style lang="scss" scoped>
    .table {
        display: block;
        width: 100%;
        border-collapse: collapse;

        thead {
            display: none;

            th {
                padding: 1.2rem 1.4rem;
                background-color: #131723;
                color: #555d75;
                font-size: 1.2rem;
                text-align: left;

                &:first-of-type {
                    border-radius: 0.8rem 0 0 0.8rem;
                }

                &:last-of-type {
                    border-radius: 0 0.8rem 0.8rem 0;
                }
            }
        }

        tbody {
            display: block;
            width: 100%;
        }

        tr {
            position: relative;
            display: block;
            text-align: left;

            &:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }

    @media screen and (min-width: 75em) {
        .table {
            display: table;

            thead {
                display: table-header-group;
            }

            tbody {
                display: table-row-group;

                tr:nth-of-type(2n) td {
                    background-color: #131925;
                }
            }

            tr {
                display: table-row;
                margin-bottom: 0;
            }
        }
    }
</style>
