<template>
    <table-component>
        <template #head>
            <tr>
                <th>ID</th>
                <th>{{ $t('statistics.table.columns.date') }}</th>
                <th>{{ $t('statistics.table.columns.client_id') }}</th>
                <th>{{ $t('statistics.table.columns.project_id') }}</th>
                <th>Steam ID</th>
                <th>{{ $t('statistics.table.columns.sum') }}</th>
                <th>{{ $t('statistics.table.columns.http_status') }}</th>
                <th></th>
            </tr>
        </template>

        <template #body>
            <tr v-for="(item, index) in items" :key="index">
                <table-cell>
                    <template #heading>ID</template>

                    <template #data>{{ item.id }}</template>
                </table-cell>

                <table-cell>
                    <template #heading>{{ $t('statistics.table.columns.date') }}</template>

                    <template #data>{{ formatDate(item) }}</template>
                </table-cell>

                <table-cell>
                    <template #heading>{{ $t('statistics.table.columns.client_id') }}</template>

                    <template #data>{{ item.client_id }}</template>
                </table-cell>

                <table-cell>
                    <template #heading>{{ $t('statistics.table.columns.project_id') }}</template>

                    <template #data>{{ item.project_id }}</template>
                </table-cell>

                <table-cell>
                    <template #heading>Steam ID</template>

                    <template #data>{{ item.steam_id }}</template>
                </table-cell>

                <table-cell theme="success">
                    <template #heading>{{ $t('statistics.table.columns.sum') }}</template>

                    <template #data>{{ formatSum(item) }}</template>
                </table-cell>

                <table-cell>
                    <template #heading>{{ $t('statistics.table.columns.http_status') }}</template>

                    <template #data>
                        <postback-status
                            v-if="item.postback"
                            :status="item.postback.response_code"
                            :loading="loadingId === item.id"
                            @retry="retryPostback(item.id)"
                        />

                        <span v-else>-</span>
                    </template>
                </table-cell>

                <table-cell :stretch="false">
                    <template #data>
                        <status :status-name="item.status" />
                    </template>
                </table-cell>
            </tr>
        </template>
    </table-component>
</template>

<script>
    import { DateTime } from 'luxon';
    import { formatPrice } from '@/helpers/common';
    import { postbackAgain } from '@/services/skinsPaymentApi';
    import PostbackStatus from '@/components/statistics/PostbackStatus';
    import RetryArrowIcon from '@/components/icons/RetryArrowIcon';
    import Status from '@/components/Status';
    import TableCell from '@/components/tables/TableCell';
    import TableComponent from '@/components/tables/TableComponent';

    export default {
        name: 'DepositTable',
        components: {
            PostbackStatus,
            RetryArrowIcon,
            Status,
            TableCell,
            TableComponent,
        },
        props: {
            items: {
                type: Array,
                required: true,
            },
        },
        methods: {
            formatDate: item => DateTime.fromISO(item.created_at).toFormat('dd.LL.yy, HH:mm'),
            formatSum: item => {
                const amount = item[`amount_${item.currency.toLowerCase()}`] || item.amount || null;
                return amount ? formatPrice(amount, item.currency) : '-';
            },
            retryPostback(orderId) {
                this.loadingId = orderId;

                return postbackAgain(this.$route.params.id, orderId)
                    .then(() => this.$toast.success(this.$t('statistics.success.postback')))
                    .catch(() => this.$toast.error(this.$t('statistics.errors.postback')))
                    .finally(() => (this.loadingId = null));
            },
        },
        data() {
            return {
                loadingId: null,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .table td:last-child {
        position: absolute;
        display: flex;
        justify-content: center;
        top: 0;
        right: 0;
        width: 4.5rem;
        height: 4.5rem;

        ::v-deep span {
            flex-grow: unset;
            padding: 0;
        }
    }

    @media screen and (min-width: 75em) {
        .table td:last-child {
            display: table-cell;
            position: unset;
        }
    }
</style>
