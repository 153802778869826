<template>
    <div class="statistics-chart" ref="wrapper">
        <scroll-container type="horizontal">
            <div class="statistics-chart__canvas-wrapper" :style="{ width: `${10 * labels.length}rem` }">
                <canvas ref="chart" class="statistics-chart__canvas" />
            </div>
        </scroll-container>

        <div ref="tooltip" class="statistics-chart__tooltip" />

        <div v-if="datasets.length > 1" class="statistics-chart__buttons">
            <button
                v-for="(dataset, index) in datasets"
                :class="{ 'statistics-chart__button': true, active: selected === index }"
                :key="index"
                :style="{ '--color': dataset.color }"
                @click="selectDataset(index)"
            >
                <span>{{ processLabel(dataset.label) }}</span>
            </button>
        </div>
    </div>
</template>

<script>
    import _ from 'underscore';
    import Chart from 'chart.js/auto';
    import { getTransformStyleParts } from '@/helpers/common';
    import { transparentLastYGridLine, zebraBackgroundPlugin, chartFillGradient } from '@/helpers/chart';
    import ScrollContainer from '@/components/ScrollContainer';

    const TICKS_COUNT_Y = 6;

    export default {
        name: 'StatisticsChart',
        components: {
            ScrollContainer,
        },
        props: {
            labels: {
                type: Array,
                required: true,
            },
            datasets: {
                type: Array,
                required: true,
            },
        },
        methods: {
            initChart() {
                return new Chart(this.$refs.chart, {
                    data: {
                        datasets: this.datasets.map(dataset => ({
                            label: this.processLabel(dataset.label),
                            data: dataset.data,
                            borderColor: dataset.color,
                            pointBackgroundColor: dataset.color,
                            backgroundColor: context => chartFillGradient(context.chart, dataset.color),
                            fill: true,
                        })),
                        labels: this.labels,
                    },
                    options: {
                        animations: {
                            numbers: {
                                properties: ['y'],
                                from: ({ chart }) => chart.chartArea.bottom,
                            },
                        },
                        elements: {
                            line: {
                                borderWidth: 2,
                                tension: 0.3,
                            },
                            point: {
                                radius: 3.5,
                                hoverRadius: 3.5,
                                borderWidth: 0,
                                hoverBorderWidth: 0,
                            },
                        },
                        maintainAspectRatio: false,
                        plugins: {
                            legend: {
                                display: false,
                            },
                            tooltip: {
                                enabled: false,
                                external: context => {
                                    const tooltip = this.$refs.tooltip;
                                    const scrollContainer = context.chart.canvas.closest('.scroll-container__content');

                                    let x = context.tooltip.caretX;

                                    if (scrollContainer) {
                                        const transform = getTransformStyleParts(scrollContainer.style.transform);
                                        x = context.tooltip.caretX - (transform['translateX'] || 0);
                                    }

                                    tooltip.innerText = context.tooltip.dataPoints[0].formattedValue;

                                    tooltip.style.opacity = context.tooltip.opacity.toString();
                                    tooltip.style.top = `${context.tooltip.caretY / 10}rem`;
                                    tooltip.style.left = `${x / 10}rem`;

                                    context.chart.canvas.style.cursor = context.tooltip.opacity ? 'pointer' : 'default';
                                },
                            },
                        },
                        scales: {
                            x: {
                                grid: {
                                    color: 'transparent',
                                    drawBorder: false,
                                    tickColor: 'transparent',
                                    tickLength: 15,
                                },
                                offset: this.labels.length <= 1,
                                ticks: {
                                    align: this.labels.length > 1 ? 'inner' : 'center',
                                    color: '#3d4352',
                                    font: {
                                        family: "'Gotham Medium', sans-serif",
                                        size: 10,
                                        weight: 350,
                                    },
                                },
                            },
                            y: {
                                beginAtZero: true,
                                grace: '10%',
                                grid: {
                                    borderDash: [4, 4],
                                    color: ({ index }) => (0 === index ? 'transparent' : 'rgba(119, 129, 156, 0.1)'),
                                    drawBorder: false,
                                    tickColor: 'transparent',
                                    tickLength: 15,
                                },
                                suggestedMax: TICKS_COUNT_Y - 2,
                                ticks: {
                                    color: '#3d4352',
                                    count: TICKS_COUNT_Y,
                                    font: {
                                        family: "'Gotham Medium', sans-serif",
                                        size: 10,
                                        weight: 350,
                                    },
                                    precision: 0,
                                },
                            },
                        },
                    },
                    plugins: [zebraBackgroundPlugin, transparentLastYGridLine],
                    type: 'line',
                });
            },
            processLabel: label => (_.isFunction(label) ? label() : label),
            selectDataset(index = 0) {
                this.selected = index;
                this.datasets.forEach((dataset, index) => this.chart.setDatasetVisibility(index, this.selected === index));
                this.chart.update();
            },
        },
        data() {
            return {
                selected: 0,
                chart: null,
            };
        },
        mounted() {
            this.chart = this.initChart();
            this.selectDataset();
            window.addEventListener('resize', () => this.chart.update());
        },
    };
</script>

<style lang="scss" scoped>
    .statistics-chart {
        position: relative;
    }

    .statistics-chart__canvas {
        width: 100% !important;
        height: 30rem !important;
    }

    .statistics-chart__canvas-wrapper {
        min-width: 100%;
    }

    .statistics-chart__tooltip {
        position: absolute;
        transform: translate(-50%, -100%);
        margin: -1.5rem 0 0;
        width: max-content;
        padding: 1rem 2rem;
        border-radius: 0.5rem;
        background-color: rgba(104, 118, 151, 0.6);
        backdrop-filter: blur(0.5rem);
        color: #dde2ee;
        font-family: 'Gotham Bold', sans-serif;
        font-size: 1.3rem;
        font-weight: 700;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s;

        &:after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -0.6rem;
            border: 0.6rem solid;
            border-color: rgba(104, 118, 151, 0.6) transparent transparent transparent;
            backdrop-filter: blur(0.5rem);
        }
    }

    .statistics-chart__buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
    }

    .statistics-chart__button {
        display: flex;
        width: 33.33%;
        flex-direction: column;
        align-items: center;
        cursor: pointer;
        opacity: 0.4;
        transition: opacity 0.3s;

        span {
            display: block;
            max-width: 100%;
            color: #a4afbf;
            font-family: 'Gotham Medium', sans-serif;
            font-size: 1rem;
            font-weight: 350;
            text-transform: uppercase;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        &:before {
            content: '';
            width: 2.4rem;
            height: 0.4rem;
            margin-bottom: 0.8rem;
            border-radius: 0.8rem;
            background-color: var(--color);
        }

        &.active {
            opacity: 1;
        }
    }

    ::v-deep {
        .scroll-container:has(.scroll-container__horizontal-scrollbar:not([style*='display: none;'])) {
            padding-bottom: 1.5rem;
        }
    }

    @media screen and (min-width: 30em) {
        .statistics-chart__buttons {
            justify-content: center;
        }

        .statistics-chart__button {
            flex-direction: row;
            width: unset;

            &:before {
                margin: 0 0.8rem 0 0;
            }

            &:not(:last-child) {
                margin-right: 2.4rem;
            }
        }
    }

    @media screen and (min-width: 120rem) {
        .statistics-chart__buttons {
            margin-top: 3rem;
        }
    }
</style>
